<template>
<b-alert show :variant="varianttype">
      <b-col>
            <b-row :class="classname">
                  <b-col cols="auto" class="p-0 d-flex align-items-center">
                        <inline-svg :src="this.$images.svg(getAlertIcon())" />
                  </b-col>
                  <b-col class="my-auto">
                        <p class="my-0">
                              <slot></slot>
                        </p>
                  </b-col>
            </b-row>
      </b-col>
</b-alert>
</template>

<script>
export default {
      Name: "Alert",
      props: {            
            "variant": String,            
            centered: Boolean
      },
      methods: {
            getAlertIcon() {
                  switch (this.variant) {
                        case "info":
                              return "alert/info.svg";
                        case "clock":
                              return "alert/clock.svg";
                        case "warning":
                              return "alert/alert-circle.svg";
                        case "danger":
                              return "alert/x-circle.svg";
                        case "success":
                              return "alert/check-circle.svg";
                        default:
                              return "alert/info.svg";
                  }
            }           
      },
      computed: {
            varianttype() {
                  switch (this.variant) {
                        case "clock":
                              return "info";
                        default:
                              return this.variant;
                  }
            },
            classname() {
                  if (this.iscentered)
                        return "text-center";
                  return "text-left";
            },
            iscentered() {
                  return false;
                  //return this.centered? true: false;
            }
      }
};
</script>
